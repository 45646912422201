"use client";

import { useQuery } from "@tanstack/react-query";

import { fetchRecommendedNews } from "@/services/newsService";
import SectionHeader from "./SectionHeader";
import VerticalArticleCard from "../cards/VerticalArticleCard";
import { useEffect, useState } from "react";
import { ArticleType } from "@/types/article";

interface RecommendedNewsListProps {
  limit: number;
  repeatedArticleId?: string;
  language?: string;
}

export default function RecommendedNewsList({
  limit,
  repeatedArticleId,
  language = "ml",
}: RecommendedNewsListProps) {
  //
  const [articles, setArticles] = useState<ArticleType[] | undefined>();

  const { data, error, isFetching } = useQuery({
    queryKey: ["fetchRecommendedNews"],
    queryFn: () =>
      fetchRecommendedNews({ limit: repeatedArticleId ? limit + 1 : limit, language: language }),
  });

  useEffect(() => {
    if (repeatedArticleId) {
      const unRepeatedNewsList = data?.filter(article => article?._id !== repeatedArticleId);
      setArticles(unRepeatedNewsList);
    } else {
      setArticles(data);
    }
  }, [data, repeatedArticleId]);

  return (
    <div>
      <SectionHeader showLogo={true} title="Recommended" iconBgColor="#F00" />
      <div className="flex flex-col gap-4 mt-8">
        {articles &&
          articles?.length > 0 &&
          articles?.map((article, index) => (
            <div key={index}>
              <VerticalArticleCard article={article} variant="lg" />
              <div className="pt-3 border-b"></div>
            </div>
          ))}
      </div>
    </div>
  );
}
