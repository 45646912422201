"use client";

import AreaChartComponent from "@/components/charts/area";
import BarChartComponent from "@/components/charts/bar";
import LineChartComponent from "@/components/charts/line";
import PieChartComponent from "@/components/charts/pie";
import TableComponent from "@/components/charts/table";
import { SpecialEventsBlock } from "@/types/page";
import { useState } from "react";
import YouthFestivalSkeleton from "./Skeleton";

type Props = {
  mode?: SpecialEventsBlock["mode"];
  data: { name: string; point: string | number }[];
  title: string;
  iconSrc?: string;
  tableHeaders?: string[];
};

export default function VisualComponent({ mode, data, title, iconSrc, tableHeaders }: Props) {
  const [count, setCount] = useState(5);

  const loadMore = () => {
    setCount(prev => prev + 5);
  };

  if (data?.length > 0)
    switch (mode) {
      case "area":
        return (
          <AreaChartComponent
            data={data?.map(v => {
              return { ...v, point: Number(v.point) };
            })}
            title={title}
          />
        );
      case "line":
        return (
          <LineChartComponent
            data={data?.map(v => {
              return { ...v, point: Number(v.point) };
            })}
            title={title}
          />
        );
      case "bar":
        return (
          <BarChartComponent
            data={data?.map(v => {
              return { ...v, point: Number(v.point) };
            })}
            title={title}
          />
        );
      case "pie":
        return (
          <PieChartComponent
            data={data?.map(v => {
              return { ...v, point: Number(v.point) };
            })}
            title={title}
          />
        );
      default:
        return (
          <TableComponent
            title={title}
            iconSrc={iconSrc}
            headers={tableHeaders || ["#", "Districts", "Points"]}
            data={
              // data?.sort((a, b) => {
              //   if (typeof b.point === "number" && typeof a.point === "number")
              //     return b.point - a.point;
              //   else return b.point > a.point ? 1 : -1;*
              // }) || []
              data || []
            }
            visibleCount={count}
            onLoadMore={loadMore}
            skeleton={<YouthFestivalSkeleton />}
          />
        );
    }
  else return <></>;
}
