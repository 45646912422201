import React from "react";

import { Typography } from "./Typography";
import Link from "next/link";

export type SectionHeadingProps = {
  iconBgColor?: string;
  title: string;
  showLogo?: boolean;
  icon?: "light" | "dark";
  titleColor?: string;
  link?: string;
};

const SectionHeader = ({
  iconBgColor = "#e74d52",
  icon = "light",
  title,
  showLogo = true,
  titleColor,
  link,
}: SectionHeadingProps) => {
  return (
    <div className="flex items-center justify-start gap-2">
      {showLogo && (
        <div
          className="relative h-[28px] w-[28px] rounded-md overflow-hidden "
          style={{ backgroundColor: iconBgColor }}
        >
          <img
            src={icon === "light" ? "/logo-symbol.svg" : "/logo-symbol-black.svg"}
            alt="deshabhimani section"
            className="w-full h-full p-1 object-fit"
            loading="lazy"
          />
        </div>
      )}
      {link ? (
        <Link href={link}>
          <Typography variant="h4" color={titleColor}>
            {title}
          </Typography>
        </Link>
      ) : (
        <Typography variant="h4" color={titleColor}>
          {title}
        </Typography>
      )}
    </div>
  );
};

export default SectionHeader;
