import { VisualizeSpecialData } from "@/types/common";

type TableComponentProps = VisualizeSpecialData & {
  iconSrc?: string;
  headers: string[];
  visibleCount: number;
  onLoadMore: () => void;
  skeleton: JSX.Element;
};

export default function TableComponent({
  title,
  iconSrc,
  headers,
  data,
  visibleCount,
  onLoadMore,
  skeleton,
}: TableComponentProps) {
  return (
    <div className="rounded-lg overflow-hidden">
      <h2 className="dark:text-white text-black py-2 font-bold mb-2 flex text-[20px]">
        {title}
        {iconSrc && (
          <img
            src={iconSrc}
            alt={title ?? "Deshabhimani - ദേശാഭിമാനി ദിനപ്പത്രം"}
            loading="lazy"
            width={40}
            height={40}
          />
        )}
      </h2>
      <div className="grid items-center grid-cols-3 bg-red-500 font-semibold text-white p-3 mb-3 rounded-lg shadow-sm">
        {headers?.map((header, index) => (
          <span
            key={index}
            className={index === 2 ? "text-center" : index === 0 ? "text-left" : ""}
          >
            {header}
          </span>
        ))}
      </div>
      {!data?.length && skeleton}
      {data?.slice(0, visibleCount)?.map((item, index) => (
        <div
          key={index}
          className="grid items-center grid-cols-3 bg-gray-100 p-3 mb-3 rounded-lg shadow-sm"
        >
          <span className="text-left">{index + 1}</span>
          <span className="truncate">{item.name}</span>
          <span className="text-center">{item.point}</span>
        </div>
      ))}
      {visibleCount < data?.length && (
        <button
          onClick={onLoadMore}
          className="flex place-content-center place-self-center load-more-btn"
        >
          More
        </button>
      )}
    </div>
  );
}
