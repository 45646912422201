import Link from "next/link";

import { Typography } from "@/components/shared/Typography";
import { getNewsDetailsUrl } from "@/utils/getNewsDetailsUrl";
import { ArticleListType } from "@/types/article";
import { getImageUrl } from "@/utils/getImageUrl";
import CustomImage from "../shared/CustomImage";
import TitleTag from "../shared/TitleTag";

interface VerticalArticleCardProps {
  variant?: "sm" | "md" | "lg" | "sidebar";
  showCategory?: boolean;
  article: ArticleListType;
  index: number;
  showNumber?: boolean;
  showDate?: boolean;
}

export default function HorizontalArticleCard({
  variant = "lg",
  showCategory = false,
  article,
  index,
  showNumber = false,
  showDate = false,
}: VerticalArticleCardProps) {
  if (!article) return null;

  const { slug, title, image, categoryId, subCategoryId, publishedAt, isLiveNews, isInPrint } =
    article;
  const date = new Date(publishedAt).toLocaleDateString(undefined, { dateStyle: "medium" });

  return (
    <div className="flex items-start gap-4 group md:pb-0">
      <div
        className={
          `relative ${
            variant === "sidebar"
              ? "aspect-[16/12] md:aspect-[16/14]"
              : variant === "sm"
              ? "aspect-[16/9.5]"
              : "aspect-[16/12] md:aspect-[16/9.5]"
          } ` +
          (variant === "sm"
            ? "w-[30%] min-w-[30%] md:min-w-[33%] md:w-[33%]"
            : "w-[30%] min-w-[30%] md:min-w-[33%] md:w-[33%]")
        }
      >
        <Link
          href={getNewsDetailsUrl(categoryId, subCategoryId, slug)}
          aria-label={`View news: ${title}`}
        >
          <div className="relative overflow-hidden rounded-lg size-full">
            <CustomImage
              src={getImageUrl(image?.sizes?.ld?.filename || image?.filename)}
              className={`object-cover object-${article?.focusPosition} w-full h-full img-zoom-hover`}
              alt={image?.alt || ""}
            />
          </div>
        </Link>

        {showNumber && (
          <div aria-live="polite" className="absolute -bottom-3 left-3">
            <div className="size-10 rounded-full overflow-hidden bg-[#FFD600] flex justify-center items-center">
              <Typography
                language="ml"
                variant="h3"
                className="text-[#36454F] dark:!text-[#36454F]"
              >
                {(index + 1).toString().padStart(2, "0")}
              </Typography>
            </div>
          </div>
        )}
      </div>
      <div className={"w-full overflow-hidden"}>
        <Link
          href={getNewsDetailsUrl(categoryId, subCategoryId, slug)}
          aria-label={`View news: ${title}`}
          className="overflow-hidden"
        >
          <h6
            className={
              "font-[600] line-clamp-3 font-anek leading-[1.5] text-[#36454F] dark:text-white " +
              (variant === "sm"
                ? " text-[15px] md:text-[13px] "
                : variant === "sidebar"
                ? " text-[15px] md:text-lg "
                : " text-[15px] md:text-base ")
            }
          >
            {isLiveNews && <TitleTag content="Live News" />}
            {isInPrint && <TitleTag content="print edition" />}
            {title}
          </h6>
        </Link>
        {showDate && (
          <Typography className="uppercase text-gray-500 mt-[6px] font-medium" variant="body2">
            {date}
          </Typography>
        )}
        {showCategory && (
          <Link
            aria-live="polite"
            href={`/${categoryId?.slug}`}
            aria-label={`View all news in the category: ${categoryId?.name}`}
          >
            <Typography className="uppercase text-gray-500 mt-[6px] font-medium" variant="body2">
              {categoryId?.name} {subCategoryId?.name ? `  |   ${subCategoryId?.name}` : ""}
            </Typography>
          </Link>
        )}
      </div>
    </div>
  );
}
