"use client";
import { VisualizeSpecialData } from "@/types/common";
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from "recharts";

export const AreaChartComponent: React.FC<VisualizeSpecialData> = ({ data, title, yAxisLabel }) => {
  return (
    <div className="p-4 bg-white shadow-lg rounded-lg">
      <h2 className="text-lg font-semibold mb-4">{title}</h2>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={data}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="25%" stopColor="#8884d8" stopOpacity={0.7} />
              <stop offset="55%" stopColor="#82ca9d" stopOpacity={0.5} />
              <stop offset="85%" stopColor="#82ca9d" stopOpacity={0.2} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" />
          <YAxis dataKey="point" />
          <Tooltip formatter={value => [value, yAxisLabel || "Value"]} />
          <Area type="basis" dataKey="point" stroke="#FF0000" fill="url(#colorUv)" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AreaChartComponent;
