import React from "react";
import { ImQuotesRight } from "react-icons/im";

interface AvatarProps {
  image?: string;
  showQuoteMark?: boolean;
  width: number;
  height: number;
  alt?: string;
}

const Avatar = ({ image, width, height, showQuoteMark = false, alt = "avatar" }: AvatarProps) => {
  return (
    <div
      className={"relative border-2 border-white rounded-full shadow shrink-0"}
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <img
        src={image || "/images/avatar.webp"}
        alt={alt??"Deshabhimani - ദേശാഭിമാനി ദിനപ്പത്രം"}
        className="object-cover w-full h-full overflow-hidden rounded-full"
        loading="lazy"
      />
      {showQuoteMark && (
        <div className="text-[#11B496] absolute top-0 left-0">
          <ImQuotesRight size={25} />
        </div>
      )}
    </div>
  );
};

export default Avatar;
