"use client";
import { VisualizeSpecialData } from "@/types/common";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export const BarChartComponent: React.FC<VisualizeSpecialData> = ({ data, title ,yAxisLabel}) => {
  return (
    <div className="p-4 bg-white shadow-lg rounded-lg">
      <h2 className="text-lg font-semibold mb-4">{title}</h2>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip formatter={value => [value, yAxisLabel || "Value"]} />
          <Bar dataKey="point" fill="#FF0000" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartComponent;
