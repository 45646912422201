import Link from "next/link";

import { Typography } from "@/components/shared/Typography";
import CustomImage from "../shared/CustomImage";
import { getNewsDetailsUrl } from "@/utils/getNewsDetailsUrl";
import { ArticleListType } from "@/types/article";
import { getImageUrl } from "@/utils/getImageUrl";
import TitleTag from "../shared/TitleTag";

interface VerticalArticleCardProps {
  variant?: "md" | "lg";
  article: ArticleListType;
  showSummary?: boolean;
}

export default function VerticalArticleCard({
  variant = "md",
  article,
  showSummary = false,
}: VerticalArticleCardProps) {
  if (!article) return null;

  const { title, slug, image, summary, categoryId, subCategoryId, isLiveNews, isInPrint } = article;

  return (
    <div className="group">
      <Link
        href={getNewsDetailsUrl(categoryId, subCategoryId, slug)}
        aria-label={`View news: ${title}`}
      >
        <div className="relative w-full aspect-[16/10] rounded-xl overflow-hidden">
          <CustomImage
            src={getImageUrl(
              (variant === "lg" ? image?.sizes?.sd?.filename : image?.sizes?.ld?.filename) ||
                image?.filename,
            )}
            className={`w-full h-full object-cover object-${article.focusPosition} img-zoom-hover`}
            alt={image?.alt || ""}
            phImg={variant === "lg" ? "/images/placeholder-sd.png" : "/images/placeholder-ld.png"}
          />
        </div>
      </Link>
      <div className={variant === "md" ? "mt-3" : "mt-4"}>
        <Link
          href={getNewsDetailsUrl(categoryId, subCategoryId, slug)}
          aria-label={`View news: ${title}`}
        >
          <Typography
            language="ml"
            variant={variant === "md" ? "h6" : "h4"}
            className="line-clamp-3"
          >
            {isLiveNews && <TitleTag content="Live News" />}
            {isInPrint && <TitleTag content="print edition" />}
            {title}
          </Typography>
        </Link>

        {showSummary && (
          <Link
            href={getNewsDetailsUrl(categoryId, subCategoryId, slug)}
            aria-live="polite"
            aria-label={`View news: ${title}`}
          >
            <Typography variant="body1" language="ml" className="mt-3 line-clamp-4">
              {summary}
            </Typography>
          </Link>
        )}
      </div>
    </div>
  );
}
