export default function YouthFestivalSkeleton() {
  return (
    <>
      {[...Array(5)].map((_, index) => (
        <div
          key={index}
          className="flex items-center justify-between bg-gray-100 dark:bg-gray-800 p-3 mb-3 rounded-lg shadow-sm"
        >
          <div className="h-6 bg-gray-300 dark:bg-gray-700 rounded w-6"></div>
          <div className="h-6 bg-gray-300 dark:bg-gray-700 rounded w-1/2"></div>
          <div className="h-6 bg-gray-300 dark:bg-gray-700 rounded w-12"></div>
        </div>
      ))}
    </>
  );
}
