interface TitleTagProps {
  content: string;
}

export default function TitleTag({ content }: TitleTagProps) {
  return (
    <>
      <span className="inline-block bg-[#fdeced] text-[#ec1c34] px-[5px] py-1 rounded text-[10px] font-montserrat uppercase font-[600] mr-1 mb-1 leading-none align-middle">
        {content}
      </span>{" "}
    </>
  );
}
