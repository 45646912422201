"use client";
import { VisualizeSpecialData } from "@/types/common";
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell, Legend } from "recharts";

export const PieChartComponent: React.FC<VisualizeSpecialData> = ({ data, title }) => {
  const COLORS = [
    "#2cf135",
    "#4A90E2",
    "#50E3C2",
    "#F5A623",
    "#D0021B",
    "#641bba",
    "#8B572A",
    "#B8E986",
    "#195014",
  ];
  return (
    <div className="p-4 bg-white shadow-lg rounded-lg">
      <h2 className="text-lg font-semibold mb-4">{title}</h2>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            dataKey="point"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={100}
            fill="#8884d8"
          >
            {data?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend
            align="center"
            payload={data?.map(({ name, point }, index) => {
              return {
                id: `${index}-${name}`,
                value: `${name?.slice(0,15)} : ${point}`,
                color: COLORS[index % COLORS.length],
              };
            })}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PieChartComponent;
