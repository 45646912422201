"use client";
import { VisualizeSpecialData } from "@/types/common";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from "recharts";

export const LineChartComponent: React.FC<VisualizeSpecialData> = ({ data, title ,yAxisLabel}) => {
  return (
    <div className="p-4 bg-white shadow-lg rounded-lg">
      <h2 className="text-lg font-semibold mb-4">{title}</h2>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip formatter={value => [value, yAxisLabel || "Value"]} />
          <Line type="natural" dataKey="point" stroke="#FF0000" strokeWidth={3} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChartComponent;
